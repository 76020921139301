const cvcReducer = (
    state = {
        board: null,
        loading: true
    }, action
    ) => {
    switch (action.type) {
        case 'CVC/UPDATE/SUBMITTED':
            return {
                ...state
            }
        case 'CVC/UPDATE/SUCCESS':
            let data = action.payload;
            return {
                ...state,
                board: data,
                loading: false
            }
        default:
            return state
    }
}

export default cvcReducer