// Styling
import './index.css';

// Framework
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { BrowserRouter as Router } from "react-router-dom";

// Data management
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import allReducers from './reducers';
import {Helmet} from "react-helmet";
import { FirebaseProvider } from './firebase-provider';  // Import the new FirebaseProvider

// Enable dev tools
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Create Redux store
const store = createStore(
    allReducers,
    composeEnhancer(applyMiddleware(thunk))
)

Sentry.init({
    dsn: "https://e03872f832444a62a21050d8eed6cba4@o254594.ingest.sentry.io/5860101",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
});

// APP //
ReactDOM.render(
    <Provider store={store}>
        <FirebaseProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Townbeach Portal</title>
                <link rel="icon" type="image/png" href="./assets/favicon.png"></link>
            </Helmet>
            <Router>
                <App/>
            </Router>
        </FirebaseProvider>
    </Provider>,
    document.getElementById('root')
);