import { toggleNotification, changeMessage, changeType } from './notification-actions';
import {ref, set} from "firebase/database";

export function saveSettings(cloudSettings, dispatch, database){
    let gamesRef = ref(database, "settings/");

    dispatch({
        type: "SETTINGS/UPDATE/STARTED",
    });

    set(gamesRef, cloudSettings).then(() => {
        dispatch({
            type: "SETTINGS/UPDATE/SUCCESS",
            payload: cloudSettings
        })

        changeMessage("Saved settings successfully", dispatch)
        changeType("SUCCESS", dispatch)
        toggleNotification(dispatch)
    })
}